.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #002e8c;
  padding: 16px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;

  @media screen and (max-width: 1600px) {
    font-size: 14px;
  }
}
