$primary-red: #002e8c;
$greyish-blue: #7689b0;
$icon-red-color: #800022;
$icon-green-color: #0b4124;
$icon-background-light-red-color: #fee2e9;
$icon-background-light-green-color: #caf4de;
$font-color: #162752;

@mixin transactionRowSpacing {
    & > span:nth-child(1) {
        width: 20%;
        @media screen and (max-width: 900px) {
            width: 30%;
        }
    }

    & > span:nth-child(2) {
        width: 15%;
    }

    & > span:nth-child(3) {
        width: 30%;
    }
    & > span:nth-child(4) {
        width: 17%;
    }
    & > span:nth-child(5) {
        width: 17%;
        @media screen and (max-width: 600px) {
            width: auto;
        }
    }
    & > a {
        width: 30%;
    }
}
.infinite-scroll-component__outerdiv,
.infinite-scroll-component {
    width: 100%;
}
.ant-pagination-item {
    background: transparent;
    border: none;
    color: #162752;
    font-size: 17px;
    font-weight: 600;
}
.ant-pagination-item-active a {
    background: #691aaa 0% 0% no-repeat padding-box;
    color: white;
    border-radius: 50%;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    background: #e2f2fa 0% 0% no-repeat padding-box;
    border-radius: 50%;
    border: none;
}
.ant-pagination {
    text-align: center;
}
.ant-empty {
    flex-direction: column;
    // margin: 50px 0;
    & > span {
        color: #7689b0 !important;
        text-transform: capitalize;
        margin-bottom: 1.2em;
        font-size: 18px;
    }
}
.transactions {
    width: 100%;
    height: 100%;
    display: inline-block;
    padding: 0 1em;
    @media screen and (max-width: 600px) {
        padding: 0;
    }
    // margin-top: 70px;

    .topdv {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 1.5rem;
        @media screen and (max-width: 600px) {
            margin-top: 30px;
        }
        .rd {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 17px;
            color: #009ee4;
            font-weight: 500;
            @media screen and (max-width: 1024px) {
                font-size: 13px;
            }
            @media screen and (max-width: 768px) {
                align-self: start;
                font-size: 10px;
            }
            span {
                cursor: pointer;
            }
            a {
                color: #009ee4;
            }
            .searchT {
                display: flex;

                .search-input {
                    width: 390px;
                    margin-right: 15px;
                    @media screen and (max-width: 600px) {
                        width: 40vw;
                    }
                }
                .drop {
                    width: 264px;
                }
                .field {
                    .control {
                        .input {
                            border-radius: 50px;
                            border: 1px solid #691aaa;
                        }

                        .select {
                            width: 100%;
                            @media screen and (max-width: 600px) {
                                width: 40vw;
                            }
                        }
                        select {
                            border-radius: 50px;
                            background-color: #691aaa;
                            color: #ffffff;
                            width: 100%;
                        }
                    }
                }
                .select:not(.is-multiple):not(.is-loading)::after {
                    border-color: #ffffff;
                }
            }
        }
    }

    .title {
        color: $primary-red;
        font-size: 28px;
        font-weight: 400;
        @media screen and (max-width: 1024px) {
            font-size: 20px;
        }
    }

    .transactionContainer {
        width: 100%;
        // margin: 1.5em 0;
        font-size: 14px;
        height: 100%;
        padding: 1rem;
        @media screen and (max-width: 600px) {
            padding: 0;
        }
        & > div {
            display: flex;
            align-items: center;
        }

        .transactionContainer__header {
            justify-content: space-between;
            font-weight: 500;
            color: $greyish-blue;
            text-transform: capitalize;
            margin-bottom: 1.2em;
            font-size: 18px;
            @media screen and (max-width: 1024px) {
                font-size: 14px;
                padding: 10px;
            }
            & > span {
                display: flex;
                align-items: center;
                justify-content: center;

                & > span {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    transition: background 0.2s ease;
                    border-radius: 5px;
                    padding: 2px;
                    margin-left: 3px;

                    & .MuiSvgIcon_root {
                        color: $icon-red-color;
                        font-size: 14px;
                        transform: rotate(90deg);
                    }

                    &:hover {
                        background: $icon-background-light-red-color;
                    }

                    &[data-style-selected="true"] {
                        .MuiSvgIcon_root {
                            color: $icon-green-color;
                            transform: rotate(-90deg);
                        }

                        &:hover {
                            background: $icon-background-light-green-color;
                        }
                    }
                }
            }

            @include transactionRowSpacing;
        }

        .transactionContainer__transactionRows {
            display: flex;
            flex-direction: column;
            height: 60%;
            overflow-y: auto;
            overflow-x: hidden;
            justify-content: center;
            @media screen and (min-width: 601px) {
                &::-webkit-scrollbar {
                    width: 4px;
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: #10e674;
                    border-radius: 24px;
                }

                &::-webkit-scrollbar-track {
                    background: #caf4de80;
                    border-radius: 24px;
                }
            }
            & > .transactionContainer__transactionRow:not(:first-child) {
                border-top: 1px solid rgba(0, 0, 0, 0.04);
            }

            .transactionContainer__transactionRow {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $font-color;
                font-size: 17px;
                font-weight: 500;
                padding: 1.2em 0;
                @media screen and (max-width: 1024px) {
                    font-size: 12px;
                }

                @include transactionRowSpacing;

                & > span,
                & > a {
                    justify-content: center;
                    text-align: center;
                }
                & > a {
                    color: #009ee4;
                }
                & > span:nth-child(1) {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;

                    padding-left: 1%;
                }

                span img {
                    margin-right: 1rem;
                }
            }
        }

        .pagi {
            display: flex;
            justify-content: center;
            margin-top: 3rem;
            button {
                font-weight: 600;
                font-family: Poppins;
                color: #162752;
            }
            .MuiPaginationItem-textPrimary.Mui-selected {
                background-color: #5c2cb5;
                color: white;
            }
            li::before {
                content: "" !important;
                width: 0 !important;
            }
        }
    }
    .withborder {
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 0px 40px #7090b026;
        @media screen and (max-width: 600px) {
            background: transparent;
            min-height: 40vh;
        }
    }
}
.only_l {
    @media screen and (max-width: 900px) {
        display: none !important;
    }
}
.l {
    @media screen and (max-width: 600px) {
        display: none !important;
    }
}
.s {
    @media screen and (min-width: 600px) {
        display: none !important;
    }
}
.infinite-scroll-component {
    &::-webkit-scrollbar {
        background: transparent;
        height: 7px;
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 24px;
        background: #691aaa;
    }

    &::-webkit-scrollbar-track {
        border-radius: 24px;
        background: #f1f3fc;
    }
}
.infinite-scroll-component ::-webkit-scrollbar {
    width: 1em !important;
}

.infinite-scroll-component ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

.infinite-scroll-component ::-webkit-scrollbar-thumb {
    background-color: red !important;
    outline: 1px solid slategrey !important;
}
.notifs {
    .MuiSnackbarContent-root {
        background-color: white !important;
    }
    div {
        color: black;
        display: flex;
        padding: 18px 25px;
        flex-grow: 1;
        flex-wrap: wrap;
        font-size: 0.875rem;
        align-items: center;
        font-family: Poppins;
        font-weight: 400;
        line-height: 1.43;
        border-radius: 4px;
        letter-spacing: 0.01071em;
        background-color: white;
        box-shadow: 0px 3px 5px -1px #00000033, 0px 6px 10px 0px #00000024,
            0px 1px 18px 0px #0000001f;
    }
    span {
        font-weight: 600;
    }
}
