@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

.ant-spin-dot {
    font-size: 150px !important;
    color: #002e8c !important;
}

.ellipses {
    font-size: 30px;
    width: 15px;
}

.ellipses:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

.modal {
    z-index: 110 !important;
}

.slug-txt-input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #009ee4;
    border-radius: 80px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    padding: 15px 2rem;
}
.slug-val {
    text-align: left;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 600;
    font: normal normal medium 32px/90px Poppins;
    letter-spacing: 0px;
    color: #002e8c;
    opacity: 1;
}
.slug-check {
    margin-top: auto;
    margin-bottom: auto;
}

label {
    font-family: Poppins !important;
}

.warn-line {
    color: #de3c3c;
    font-weight: 500;
    text-align: end;
}
.cntner {
    margin-top: 60px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.ant-input-number-handler-wrap {
    display: none !important;
}

.tabss li {
    /* Makes a horizontal row */
    float: left;

    /* So the psueudo elements can be
       abs. positioned inside */
    position: relative;
    justify-content: start;
}
.tabss a {
    /* Make them block level
       and only as wide as they need */
    float: left;
    padding: 10px 40px;
    text-decoration: none;

    /* Default colors */
    color: black;
    background: white;

    /* Only round the top corners */
    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.tabss .active {
    /* Highest, active tab is on top */
    z-index: 3;
}
.tabss .active a {
    /* Colors when tab is active */
    background: white;
    color: black;
}
.tabss li:first-child.active a {
    background-color: #002e8c;
    color: white;
}
.tabss li:last-child.active a {
    background-color: #691aaa;
    color: white;
}
.tabss li:before,
.tabss li:after,
.tabss li a:before,
.tabss li a:after {
    /* All pseudo elements are 
       abs. positioned and on bottom */
    position: absolute;
    bottom: 0;
}
/* Only the first, last, and active
     tabss need pseudo elements at all */
.tabss li:last-child:after,
.tabss li:last-child a:after,
.tabss li:first-child:before,
.tabss li:first-child a:before,
.tabss .active:after,
.tabss .active:before,
.tabss .active a:after,
.tabss .active a:before {
    content: "";
}
.tabss li:first-child:before,
.tabss li:first-child:after {
    background-color: #002e8c;

    /* Squares below circles */
    z-index: 1;
}
/* Squares */
.tabss li:before,
.tabss li:after {
    background: white;
    width: 10px;
    height: 10px;
}
.tabss li:before {
    left: -10px;
}
.tabss li:after {
    right: -10px;
}
/* Circles */
.tabss li a:after,
.tabss li a:before {
    width: 20px;
    height: 20px;
    /* Circles are circular */
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: #222;

    /* Circles over squares */
    z-index: 2;
}
.tabss .active a:after,
.tabss .active a:before {
    background: white;
}
/* First and last tabss have different
     outside color needs */
.tabss li:first-child.active a:before {
    background: #222;

    display: none;
}
.tabss li:first-child a:before {
    background: #222;

    display: none;
}
.tabss li:last-child a:after,
.tabss li:last-child a:after {
    background: #f1f3fc;
}
.tabss li:last-child.active:after {
    background: #691aaa;
}

.tabss li:first-child:before {
    background: #222;

    display: none;
}

.tabss li a:before {
    left: -20px;
}
.tabss li a:after {
    right: -20px;
}

.tabss li:last-child.active:before {
    background: #691aaa;
}
.maintenance {
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    font-size: 22px;
    font-weight: 500;
}
.sorry {
    color: #691aaa;
    font-weight: 500;
    font-size: 28px;
}
