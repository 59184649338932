* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

#button-wrappered {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: #002e8c;
  width: 100%;
  height: 100px;
  position: relative;
  cursor: pointer;
  /* padding: 20px 0px; */
  overflow: visible;

  color: #002e8c;

  margin-top: 37px;
  border-radius: 50px;
  /* padding: 1em 2em; */

  font-family: Poppins;
  font-weight: 600;
  letter-spacing: 5px;
  font-size: 20px;
}

.hideArrow {
  display: none;
}

.submited,
.loader,
.loader-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.submited {
  z-index: 2;
  width: max-content;
  height: 60px;
  /* background: linear-gradient(to right, #ffb369 0%, #ff4272 100%); */
  background: #ffffff;
  display: block;
  border-radius: 200px;
  text-align: center;
  /* color: rgba(255, 255, 255, 1); */
  /* color: "#002E8C"; */
  text-decoration: none;
  line-height: 300%;
  padding: 0 2em;
  transition: all 0.7s ease, line-height 0s ease, color 0.4s ease,
    filter 0.2s ease;
}

.circle {
  height: 95px;
}
.with {
  border: 1px solid #009ee4;
}

.hover:hover {
  text-decoration: underline;
}

#button-wrapper::selection,
.submited::selection {
  background: transparent;
}

.submited:before {
  content: "";
  z-index: -2;
  width: 100%;
  height: 100%;

  display: block;
  border-radius: 200px;
  position: absolute !important;
  left: 0;
  top: 0;
  opacity: 0.8;
  transform: scale(0.9) translateY(15px);
  /* filter: blur(20px); */
  transition: all 0.2s ease;
}

.submited:after {
  content: "";
  z-index: -4;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #ffb369 0%, #ff4272 100%);
  background: "#ffffff";
  display: block;
  border-radius: 200px;
  /* position: static !important; */
  left: 0;
  top: 0;
  opacity: 0;
  transform: scale(1);
}

/* .submited:hover:before {
  transform: scale(0.9) translateY(5px);
  filter: blur(15px);
} */

.submited:active {
  line-height: 460%;
  filter: brightness(0.95);
  transition: all 0s;
}

.checked .submited:before {
  top: 0px;
  opacity: 0;
}

/* .checked .submited:after {
  animation: submited-after-anim 0.5s 4.4s cubic-bezier(0.39, 0.575, 0.565, 1);
} */

/* .checked {
  -webkit-filter: url("#goo");
  filter: url("#goo");
} */

.yellow {
  background-color: #8514e2 !important;
}

.pink {
  /* background-color: #81636b; */
  background-color: #0043cb !important;
}

.orange {
  /* background-color: #ff7b6e; */
  background-color: #002062 !important;
  transform: scale(0.2) rotate(30deg) translate(0px);
}
.color1 {
  background-color: #009ee4 !important;
}

.color2 {
  /* background-color: #81636b; */
  background-color: #002e8c !important;
}

.color3 {
  /* background-color: #ff7b6e; */
  background-color: #691aaa !important;
  transform: scale(0.2) rotate(30deg) translate(0px);
}
.blue {
  background-color: #002e8c !important;
}

.darkblue {
  background-color: #ffffff !important;
}

.white {
  background-color: #7e1cce !important;
  transform: scale(0.2) rotate(30deg) translate(0px);
}

.checked .pink {
  animation: loader-anim-pink 4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.checked .yellow {
  animation: loader-anim-yellow 4s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.checked .orange {
  animation: loader-anim-orange 4s 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.checked .darkblue {
  animation: loader-anim-pink 4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.checked .blue {
  animation: loader-anim-yellow 4s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.checked .white {
  animation: loader-anim-orange 4s 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.checked .color2 {
  animation: loader-anim-pink 4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.checked .color1 {
  animation: loader-anim-yellow 4s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.checked .color3 {
  animation: loader-anim-orange 4s 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loader {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  opacity: 0;
  z-index: 1;
}

.checked .loader {
  opacity: 1;
}

.checked .loader-wrapper {
  animation: wrapper-loader-anim 4.2s linear;
  width: 95px;
  height: 95px;
  transform: scale(0);
}

.checked .submited {
  width: 95px;
  color: rgba(255, 255, 255, 0);
  background: linear-gradient(to right, #ff7b6e 0%, #ff7b6e 100%);
  background: #ffffff;
  animation: submited-anim 4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.check-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 95px;
  height: 95px;
  z-index: -1;
  opacity: 1;
}

.check-wrapper svg {
  margin-top: 31px;
  margin-left: 16px;
}

.check {
  fill: none;
  stroke: #002e8c !important;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  z-index: -1;
}

.checked .check-wrapper {
  z-index: 3;
}

.checked .check {
  animation: check 4s 4.4s ease forwards;
}

@keyframes loader-anim-pink {
  0% {
    transform: scale(0.2) rotate(0deg) translate(220px, 0px);
  }
  100% {
    transform: scale(0.2) rotate(1100deg) translate(300px, 0px);
  }
}

@keyframes loader-anim-yellow {
  0% {
    transform: scale(0.2) rotate(0deg) translate(300px, 0px);
  }
  100% {
    transform: scale(0.2) rotate(1100deg) translate(220px, 0px);
  }
}

@keyframes loader-anim-orange {
  0%,
  70% {
    transform: scale(0.2) rotate(30deg) translate(0px);
  }
  85% {
    transform: scale(0.2) rotate(30deg) translate(-300px);
  }
  100% {
    transform: scale(0.2) rotate(30deg) translate(0px);
  }
}

@keyframes wrapper-loader-anim {
  0% {
    transform: scale(0) translateY(0px);
  }
  10% {
    transform: scale(1) translateY(20px);
  }
  50% {
    transform: scale(1.2) translateY(10px);
  }
  90% {
    transform: scale(1) translateY(-10px);
  }
  100% {
    transform: scale(0) translateY(0px);
  }
}

@keyframes submited-after-anim {
  0% {
    transform: scale(1);
    opacity: 0.3;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes submited-anim {
  0% {
    transform: scale(1);
  }
  5%,
  95% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes check {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
