.currencies_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 40px #7090b026;
    width: 23.43vw;
    padding: 30px 30px;
    border-radius: 20px;
    font-family: "Poppins", sans-serif;

    @media screen and (max-width: 1024px) {
        width: 30.3vw;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 0 0 2rem;
    }
    .Processing {
        font-size: 18px;
        width: 128px;
        display: flex;
        margin: auto 24px;
        color: #002e8c;
        font-weight: 500;
    }
    .ele {
        width: 50%;
        // margin-bottom: 20px;
    }
    .divs {
        text-align: center;
        font-family: Poppins;
        font-weight: 300;
        font-size: 40px;
        letter-spacing: 0px;
        color: #009ee4;
        opacity: 1;

        @media screen and (max-width: 1024px) {
            font-size: 28px;
        }
        @media screen and (max-width: 768px) {
            font-size: 24px;
        }
    }
    .convertbutton {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        background: #002e8c;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        top: 25%;
        left: 40%;
        z-index: 10;
        border: none;
        outline: none;
    }

    .price {
        color: #002e8c;
        font-size: 28px;
        // margin-bottom: 20px;
        display: flex;
        @media screen and (max-width: 1024px) {
            font-size: 20px;
            text-align: center;
        }
        @media screen and (max-width: 768px) {
            font-size: 18px;
            text-align: center;
        }
    }
    .action {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media screen and (max-width: 768px) {
            button {
                margin-right: 1rem;
            }
        }
    }

    .withdraw:hover {
        text-decoration: underline;
    }
    .liquidity {
        margin: auto;
        font-size: 18px;
        letter-spacing: 0px;
        font-weight: 500;
        color: #435475;
    }
    .cconvert {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
        // margin-bottom: 20px;
        .pc {
            .amount {
                text-align: left;
                font-size: 18px;
                letter-spacing: 0px;
                color: #435475;
                font-weight: 500;
                display: flex;
                @media screen and (max-width: 1024px) {
                    font-size: 14px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 12px;
                }
            }
            .acurrency {
                color: #7689b0;
                text-align: center;
                @media screen and (max-width: 1024px) {
                    font-size: 12px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 9px;
                }
            }
        }
    }
    .invest {
        width: 100%;
        margin-top: 14px;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 600px) {
            width: 80vw;
            justify-content: center;
        }
        .investtext {
            color: #162752;
            font-size: 16px;
            font-weight: 500;
            padding: 9px;
            text-align-last: center;
            margin-bottom: 0;
            padding-bottom: 0;
            z-index: 10;
            @media screen and (max-width: 1024px) {
                font-size: 12px;
            }
        }
    }
}
.highlight_div {
    box-shadow: 0px 0px 40px #691aaa4f;
    border: 1px solid #691aaa;
}
.right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25vw;
    @media screen and (max-width: 1024px) {
        width: 100%;
        flex-direction: row;
        margin-top: 30px;
        display: none;
    }
    // @media screen and (max-width: 520px) {
    //   width: 100%;
    //   flex-direction: column;
    //   margin-top: 30px;
    // }
}
.boxx {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px #7090b026;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    display: flex;
    height: 47%;
    padding: 10px;
    @media screen and (max-width: 520px) {
        margin-bottom: 10px;
    }
    .imgg {
        background: #f0f2fc 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 0.4;
        display: flex;
        padding: 0 30px;
    }
    .side_box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 30px;
        margin: auto 0;
        & > span:nth-child(1) {
            text-align: left;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0px;
            color: #7689b0;
            opacity: 1;
        }
        & > span:nth-child(2) {
            text-align: left;
            font-size: 36px;
            font-weight: 600;
            letter-spacing: 0px;
            color: #009ee4;
            opacity: 1;
        }
        & > span:nth-child(3) {
            text-align: left;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0px;
            color: #009ee4;
        }
    }
}
