$primary-red: #002e8c;
$primary-green: #009ee4;
$background-green: #eafbf3;
$background-dark-green: #caf4de;
$greyish-blue: #435475;

@mixin dividendsMainText {
    cursor: pointer;
    font-weight: 300;
    font-size: 2em;
    color: $primary-green;
    border-radius: 24px;
    // padding: 4px 8px;
    transition: background 0.2s ease;
    display: flex;

    &:hover {
        background: $background-green;
    }
}

.dividends {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    height: 270px;
    border-radius: 20px;
    box-shadow: 0 0 40px #7090b026;
    font-weight: 600;
    padding: 1em 1.4em;
    margin-right: 1em;
    font-family: "Poppins", sans-serif;
    margin-bottom: 1rem;
    @media screen and (max-width: 600px) {
        width: 42vw;
    }
    @media screen and (max-width: 350px) {
        width: 100vw;
    }
    .dividends__heading {
        font-size: 18px;
        color: $primary-red;
        margin-bottom: 5px;
        text-transform: capitalize;
    }

    .dividends__value {
        text-align: center;
        justify-content: center;
        margin: 15px auto;
        @include dividendsMainText;
    }

    .dividends__drop {
        @include dividendsMainText;
        position: relative;
        font-weight: 600;
        align-items: center;
        // height: 0;
        .dividends__dropMainValue {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 2;
            padding: 4px 3px;
        }

        .dividends__dropSubValue {
            width: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 1;
            padding: 4px 8px;
            left: 0;
            border-radius: 5px;
            background: $background-green;
            opacity: 0;
            pointer-events: none;
            transition: all 0.2s ease;
            animation: fadeOut 0.2s linear;
            max-height: 160px;
            overflow-y: scroll;
            top: 0;
            z-index: 5;
            &::-webkit-scrollbar {
                background: transparent;
                height: 7px;
                width: 7px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 24px;
                background: $greyish-blue;
            }

            &::-webkit-scrollbar-track {
                border-radius: 24px;
                background: #e9ecf9;
            }
            &[data-style-open="true"] {
                opacity: 1;
                pointer-events: auto;
                animation: fadeIn 0.3s linear;
            }

            & > span:first-child {
                pointer-events: none;
                // color: transparent;
                padding: 4px 3px;
            }

            & > span:not(:last-child) {
                border-bottom: 0.1px solid #1d97561f;
            }

            & > span:not(:first-child) {
                // font-size: 14px;
                // padding: 7px 4px;
            }

            span {
                width: 100%;
                cursor: pointer;
                transition: background 0.5s ease;

                &:hover {
                    background: $background-dark-green;
                }
            }

            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            @keyframes fadeOut {
                0% {
                    opacity: 1;
                }

                100% {
                    opacity: 0;
                }
            }
        }
    }

    .back {
        background-color: $background-green;
        border-radius: 5px;
    }

    .dividends__footer {
        display: flex;
        // flex-direction: column;
        align-items: center;
        color: $greyish-blue;
        margin: 5px;
        font-size: 12px;

        span {
            margin: 1px 0;
        }
    }
    .dividends__footer_ {
        display: flex;
        // flex-direction: column;
        align-items: center;
        color: #26de98;
        margin: 5px;
        font-size: 12px;

        span {
            margin: 1px 0;
        }
    }
    span#top {
        margin-left: 15px;
    }
    .down {
        @media screen and (max-width: 1200px) {
            bottom: 18px;
        }
        @media screen and (min-width: 1201px) {
            top: 15px;
        }
    }
}
