.banner_ {
    background-color: rgb(0, 46, 140);
    text-align: center;
    padding: 1vh;
    // position: fixed;
    width: 100%;
    a {
        color: white;
        font-weight: 500;
        letter-spacing: 1px;
        text-decoration: none;
    }
    svg {
        margin: -6px 4px;
    }
}
.actionbar_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 2em 0 0 0;
    font-size: 18px;
    position: fixed;
    // background-color: #f1f3fc;
    z-index: 60;
    width: 100vw;
    padding: 20px 40px 20px 20px;
    height: 100px;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    @media screen and (max-width: 1342px) {
        height: 75px;
    }
    // @media screen and (max-width: 600px) {
    //     padding: 0;
    // }
}

.action_item {
    color: #009ee4;
    font-weight: 600;
    @media screen and (max-width: 1342px) {
        font-size: 12px;
    }
}
.visit {
    color: #009ee4;
    font-weight: 500;
    text-decoration: none;
    font-family: Poppins;
    @media screen and (max-width: 1342px) {
        font-size: 12px;
    }
}

.action_stat {
    display: flex;
    align-items: center;
    color: #435475;
    font-weight: 500;
    @media screen and (max-width: 1342px) {
        font-size: 12px;
    }

    svg {
        margin: 0 !important;
    }
    .stat {
        margin-left: 10px;
    }
    img {
        width: 19px;
        height: 24px;
    }
    .ant-btn {
        outline: none !important;
        border: none !important;
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
}

.link {
    font-weight: 600;
    letter-spacing: 5.76px;
    color: #012d8c;
    text-transform: uppercase;
    text-align: left;
    font: normal normal 600 16px/90px Poppins;
    letter-spacing: 5.76px;
    color: #012d8c;
    text-transform: uppercase;
    opacity: 1;
}

.link:hover {
    text-decoration: underline !important;
}
.with {
    @media screen and (max-width: 1760px) {
        display: none;
    }
}
.l {
    @media screen and (max-width: 768px) {
        display: none !important;
    }
}
.lt {
    @media screen and (max-width: 900px) {
        display: none !important;
    }
    @media screen and (max-width: 1400px) {
        transform: scale(0.8);
    }
}
.s {
    @media screen and (min-width: 768px) {
        display: none !important;
    }
}
