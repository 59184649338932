$default-green: #2bd67b;
$secondary-green: #1d9756;
$default-background-red: #4d0014;
$secondary-background-red: #3c0010;
$primary-red: #ff0144;
$secondary-red: #cc0136;
$greyish-blue: #435475;
$default-pink: #ff799d;
$light-pink: #ffd7e1;
@mixin sectionBaseStyles {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@mixin sectionHeading($font-color: null) {
    color: $font-color;
    font-size: 50px;
    font-weight: 300;
}

@mixin quoteStyles($font-color: null) {
    font-weight: 500;
    font-style: italic;
    font-size: 1em;
    color: $font-color;
    margin: 1em 0;
}

@mixin quoteAuthorStyles($font-color: null) {
    font-weight: 500;
    font-size: 0.8em;
    color: $font-color;
}

@mixin sectionFooter($font-color: null) {
    color: $font-color;
    font-style: italic;
    font-size: 1.4em;
    font-weight: 300;
    margin-top: 1em;
}
.banner {
    // margin-top: 90px;
    text-align: center;
    line-height: 30px;
    display: flex;
    letter-spacing: 0px;
    color: #002e8c;
    opacity: 1;
    font-weight: 500;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 67px;
    background: #f4f6fe;
    z-index: 101;
    .txt {
        margin: auto 2px;
        font-size: 1.1em;
    }
    img {
        width: 30px;
        height: 30px;
        margin: auto 0;
    }
    #bnb {
        width: 27px;

        margin: auto 5px auto 8px;
    }
    @media screen and (max-width: 600px) {
        display: none;
    }
}
.social__body {
    background-color: #f1f3fc;
    padding-top: 156px;
    @media screen and (max-width: 600px) {
        // padding: 30px 4vw;
        padding-top: 70px;
    }
}

.qhead {
    color: #012d8c;
    font-size: 50px;
    font-weight: 300;
}

.questionsContainer {
    @include sectionBaseStyles;
    padding: 3em 10vw;
    font-family: "Poppins", sans-serif;
    @media screen and (max-width: 600px) {
        padding: 3em 4vw;
    }
    & > span:first-child {
        @include sectionHeading(#012d8c);
    }

    & > span:nth-child(2) {
        @include quoteStyles($secondary-green);
    }

    & > span:nth-child(3) {
        @include quoteAuthorStyles($secondary-green);
    }

    .questionsBlock {
        width: 100%;
        padding: 3em 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        text-align: left;

        & > .question:not(:last-child) {
            margin-bottom: 1.4em;
        }

        .question {
            width: 100%;
            min-height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $greyish-blue;
            font-weight: 500;
            padding: 0.5rem 2em;
            border-radius: 16px;
            box-shadow: 0 0 40px #7090b026;
            background: #ffffff;
            font-size: 20px;
            font-family: "Poppins", sans-serif;
        }

        .answer {
            width: 100%;
            color: $greyish-blue;
            font-weight: 500;
            padding: 1em 2.4em;
            padding-bottom: 2em;
            background: transparent;
            font-size: 18px;
            @media screen and (max-width: 600px) {
                padding: 1em;
            }
            ul li::before {
                content: url(https://res.cloudinary.com/dgdjabqwr/image/upload/v1610015801/cd3d/CETO_-_Icon_-_Cyan_1_n7ezmc.png) !important;

                font-weight: bold;
                font-size: 1.5em !important;
                display: inline-block;
                width: 1em;
            }
            div {
                margin-bottom: 1em;
            }

            ul {
                margin-left: 2em;
                list-style: none;

                li {
                    margin: 8px 0;
                }

                li::before {
                    content: "•";
                    color: $default-green;
                    font-weight: bold;
                    font-size: 1.2em;
                    display: inline-block;
                    width: 1em;
                }
            }
        }
    }
}
