$default-green: #2bd67b;
$secondary-green: #1d9756;
$default-background-red: #4d0014;
$secondary-background-red: #3c0010;
$primary-red: #ff0144;
$secondary-red: #cc0136;
$greyish-blue: #435475;
$default-pink: #ff799d;
$light-pink: #ffd7e1;
.bannerr {
    // margin-top: 90px;
    text-align: center;
    line-height: 30px;
    display: flex;
    letter-spacing: 0px;
    color: #002e8c;
    opacity: 1;
    font-weight: 500;
    justify-content: center;
    position: fixed;
    width: 100vw !important;
    height: 67px;
    background: #f4f6fe;
    z-index: 101;
    .txt {
        margin: auto 2px;
        font-size: 1.1em;
    }
    img {
        width: 30px;
        height: 30px;
        margin: auto 0;
    }
    #bnb {
        width: 27px;

        margin: auto 5px auto 8px;
    }
    @media screen and (max-width: 600px) {
        display: none;
    }
}
.social__body {
    background-color: #f1f3fc;
    font-family: "Poppins", sans-serif;
    padding: 156px 0;
    min-height: 100vh;
    @media screen and (max-width: 600px) {
        padding: 156px 4vw;
    }

    .heads {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 60px;
        padding-top: 50px;
        @media screen and (max-width: 600px) {
            text-align: center;
            padding-left: 0px;
            padding-top: 0px;
        }
        .display_flex {
            display: flex;
            @media screen and (max-width: 600px) {
                flex-direction: column;
            }
        }
        .ant-switch {
            background: rgb(105, 26, 170);
            transform: scale(1.5);
        }
        .ant-switch-checked {
            background-color: #002e8c;
        }
        .th {
            font-size: 50px;
            font-weight: 300;
            color: #012d8c;
        }
        .ph {
            font-size: 50px;
            font-weight: 500;
            color: #012d8c;
            margin-bottom: 0;
        }
        .ant-dropdown-link {
            color: #012d8c;
            font-weight: 500;
            margin: 1.8em 2em;
            font-size: 16px;
        }
        .menu_item {
            color: #012d8c;
            font-weight: 500;
            font-size: 16px;
        }
    }
    .midinfo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 60px;
        margin-top: 60px;
        @media screen and (max-width: 600px) {
            padding-left: 0px;
        }
        .mascot {
            position: absolute;
            right: -190px;
        }
        .motto {
            color: #691aaa;
            font-size: 40px;
            position: absolute;
            left: 5%;
            font-weight: 300;
            font-style: italic;

            span {
                font-weight: 500;
            }
        }
        .doc_container {
            padding-bottom: 50px;
            .react-pdf__Page {
                display: flex;
                justify-content: center;
                margin-right: 20px;
                @media screen and (max-width: 600px) {
                    margin-right: 0;
                    .react-pdf__Page__canvas {
                        width: 100% !important;
                    }
                }
            }

            .page_num {
                margin: 3rem 0;
                display: flex;
                align-items: center;
                justify-content: center;

                button {
                    font-weight: 600;
                    font-family: Poppins;
                    color: #162752;
                }
                .MuiPaginationItem-textPrimary.Mui-selected {
                    background-color: #5c2cb5;
                    color: white;
                }
                li::before {
                    content: "" !important;
                    font-weight: bold;
                    font-size: 1.5em !important;
                    display: inline-block;
                    width: 0 !important;
                }
            }
        }
    }
}
.l {
    @media screen and (max-width: 600px) {
        display: none !important;
    }
}
.s {
    @media screen and (min-width: 600px) {
        display: none !important;
    }
}
