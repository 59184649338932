$primary-red: #cc0136;
$primary-green: #1d9756;
$background-green: #eafbf3;
$background-dark-green: #caf4de;
$greyish-blue: #435475;
@mixin dividendsMainText {
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    color: $greyish-blue;
    border-radius: 24px;
    padding: 0 8px;
    transition: background 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #435475;
    opacity: 1;
    @media screen and (max-width: 1024px) {
        font-size: 13px;
    }
    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
}
.dividends__drop {
    @include dividendsMainText;
    position: relative;
    z-index: 80;

    .dividends__dropMainValue {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 3px;
    }

    .reduced {
        @media screen and (max-width: 1342px) {
            font-size: 12px;
        }
    }
    .dividends__dropSubValue {
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        top: 0;
        padding: 4px 8px;
        left: 0;
        border-radius: 5px;
        background: $background-green;
        opacity: 0;
        pointer-events: none;
        transition: all 0.2s ease;
        animation: fadeOut 0.2s linear;

        &[data-style-open="true"] {
            opacity: 1;
            pointer-events: auto;
            animation: fadeIn 0.3s linear;
        }

        & > span:first-child {
            pointer-events: none;
            font-size: 14px;

            padding: 4px 3px;
        }

        & > span:not(:last-child) {
            border-bottom: 0.1px solid #1d97561f;
        }

        & > span:not(:first-child) {
            font-size: 14px;
            padding: 7px 4px;
        }

        span {
            width: 100%;
            cursor: pointer;
            transition: background 0.5s ease;

            &:hover {
                background: $background-dark-green;
            }
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @keyframes fadeOut {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }
    }
}
