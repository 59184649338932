$primary-red: #002e8c;

.holdings {
    font-family: Poppins;
    flex: 1;
    // display: flex;
    // width: 30%;
    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0 2vw;
    }
    .container1 {
        display: flex;
        flex-direction: column;
        // justify-content: space-evenly;
        width: 220px;
        height: 190px;
        background: #fff;
        border-radius: 20px;
        box-shadow: 0 0 40px #7090b026;
        font-weight: 600;
        padding: 1em 1.4em;
        margin-right: 1em;
        font-family: "Poppins", sans-serif;
        margin-bottom: 1rem;
        @media screen and (max-width: 600px) {
            width: 42vw;
        }
        @media screen and (max-width: 350px) {
            width: 100vw;
        }
        .dividends__heading {
            font-size: 18px;
            color: $primary-red;
            margin-bottom: 5px;
            text-transform: capitalize;
        }
    }
    .tokens {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 40px #7090b026;
        border-radius: 20px;
        padding: 20px;
        opacity: 1;
        width: 220px;
    }
    .head {
        text-align: left;
        font-size: 18px;
        font-weight: 600;

        letter-spacing: 0px;
        color: #002e8c;
        opacity: 1;
    }
    .ceto {
        text-align: left;
        font-weight: 300;
        font-family: Poppins;
        font-size: 22px;
        letter-spacing: 0px;
        color: #009ee4;
        opacity: 1;
    }
    .holdings__title {
        color: $primary-red;
        font-size: 28px;
        @media screen and (max-width: 1024px) {
            font-size: 20px;
        }
        @media screen and (max-width: 768px) {
            font-size: 18px;
        }
    }

    .holdingsCards {
        display: flex;
        align-items: center;
        margin: 1.5em 0;
        width: 100%;
        // justify-content: space-between;
        @media screen and (max-width: 1200px) {
            flex-wrap: wrap;
        }
        @media screen and (max-width: 500px) {
            justify-content: center;
        }
    }
    .holdingsCard {
        display: flex;
        flex-direction: column;
        // height: 190px;
        background: #002e8c;
        border-radius: 20px;
        box-shadow: 0 0 40px #7090b026;
        font-weight: 600;
        padding: 1em 0;
        margin-right: 1em;
        font-family: "Poppins", sans-serif;
        margin-bottom: 1rem;
        width: 50%;
        height: 270px;
        cursor: pointer;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
        .logo {
            width: 42%;
            margin: auto;
        }

        .exchange {
            color: #1fd0ff;
            display: flex;
            justify-content: center;
            .txt {
                text-align: right;
                font: normal normal 600 14px/90px Poppins;
                letter-spacing: 5.04px;
                color: #1fd0ff;
                text-transform: uppercase;
                opacity: 1;
            }
            img {
                width: 20px;
                height: fit-content;
                margin: auto 0.5em;
            }
        }
        .foot {
            justify-content: center;
            display: flex;
            border-top: 1px solid #ffffff3b;
            padding-top: 0.5em;

            img {
                margin: auto;
            }
        }
    }
    .black {
        background-color: black;
    }
}
