$light-pink: #fff1f5;

.socialCard {
  background-repeat: no-repeat;
  background-size: cover;
  font-weight: 300;
  padding: 2em 2.4em;
  border-radius: 20px;
  color: $light-pink;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  font-family: "Poppins", sans-serif;

  & > div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    opacity: 0.9;
    margin-bottom: 12px;
    font-weight: 700;

    p {
      font-weight: 600;
    }

    .socialCard__logo {
      width: 50px;
      height: 50px;
      margin-right: 0.5em;
    }
  }

  & > div:last-child {
    font-size: 32px;
  }
}
