$background-red: #002e8c;
$background-brown: #6a001c;
$background-light-red: #cc0136;
$background-green: #4cdc8f;
$light-pink: #fff1f5;
$light-blue: #eafbf3;

.tokensTimeline {
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 370px;
  background: $background-red;
  border-radius: 20px;

  .tokensTimeline__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $light-pink;
    font-size: 28px;
    opacity: 0.9;

    & > span:last-child {
      font-size: 18px;
    }
  }

  .tokensTimeline__timeline {
    position: relative;
    background: $background-brown;
    border-radius: 64px;
    padding: 1.2em 3em;
    color: #fff;
    font-weight: 400;
    opacity: 0.9;

    .tokensTimeline__timelineFilled {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 56%;
      z-index: 2;
      border-radius: 64px;
      display: flex;
      align-items: center;
      padding-left: 6em;
      background: $background-light-red;
    }

    .tokensTimeline__timelineCircle {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $background-green;
      top: -50%;
      left: 50%;
      z-index: 3;
      padding: 1.4em;
      border-radius: 50%;
      transform: translate(-50%, 28%) rotate(90deg);
      box-shadow: 0 0 30px #00000066;
    }
  }

  .tokensTimeline__timelineHelperText {
    font-size: 18px;
    color: $light-blue;
    text-align: center;
    opacity: 0.9;
  }
}
