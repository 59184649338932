.adSlider {
  position: relative;
  height: 180px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 1em 2.4em;

  .adSlider__dots {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin: 0 4px;

      .MuiSvgIcon_root {
        color: #fff;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}
