$default-green: #2bd67b;

.social {
    background-color: #f1f3fc;

    .social_container {
        // padding: 30px 2vw;
        padding-top: 173px;

        min-height: 100vh;
        // @media screen and (max-width: 600px) {
        //     padding-top: 126px;
        // }
    }

    iframe {
        margin-bottom: 30px;
        border-radius: 20px;
        background-color: #f1f3fc;
        .timeline-Widget {
            background-color: #f1f3fc !important;
        }
    }
    .social__body {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 2vw 5vh;
        margin-top: 100px;

        @media screen and (max-width: 1024px) {
            flex-direction: column-reverse;
        }
        @media screen and (max-width: 600px) {
            margin-top: 75px;
        }
        & > div {
            display: flex;
            flex-direction: column;
        }

        .social__bodyLeft {
            width: 65%;
            @media screen and (max-width: 1024px) {
                width: 100%;
                margin: 1.5em 0;
            }
            .social__bodyLeftHalfCards {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 0.5em 0;

                & > div {
                    width: 49%;
                    @media screen and (max-width: 600px) {
                        width: 100%;
                    }
                    margin: 0.8em 0;
                }
            }
        }

        .social__bodyRight {
            width: 33%;

            @media screen and (max-width: 1024px) {
                width: 100%;
                margin-top: 20px;
            }
            .social__mixMingle {
                // background: url("/social-mingle-background.png");
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 20px;
                padding: 2em 1em;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1.5em;

                background: linear-gradient(to right, #002e8c, #691aaa);
                & > span:first-child {
                    color: #ffffff;
                    font-size: 32px;
                }

                .social__mixMingleSocial {
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    margin-top: 1em;
                }
            }
        }
    }
}
