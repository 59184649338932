.banner {
    text-align: center;
    line-height: 30px;
    display: flex;
    letter-spacing: 0px;
    color: #002e8c;
    opacity: 1;
    font-weight: 600;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 40px;
    background: #f1f3fc;
    z-index: 100;
}
.banner > .txt {
    margin: auto 2px;
    font-size: 1em;
}
.banner > .txt > a {
    text-decoration: underline;
    color: #002e8c;
}
.banner-txt-2 {
    margin: auto 0 auto 4px;
    font-size: 1em;
    color: #691aaa !important;
    font-weight: 600;
}
.banner > img {
    width: 30px;
    height: 30px;
    margin: auto 0;
}
.banner > #bnb {
    width: 27px;

    margin: auto 5px auto 8px;
}
.ethh_logo {
    width: 20px;
    height: 20px;
}
.bnbb_logo {
    width: 20px;
    height: 20px;
    margin: 0 4px;
}

.banner > .buyy {
    color: rgb(0, 158, 228);
    background: transparent;
    box-shadow: none;
    font-size: 1em;
    text-transform: uppercase;
    margin: auto 0 auto 2em;
    letter-spacing: 3px;
    padding: 0px;
}
.banner > .buyy:hover {
    text-decoration: underline;
}
/* @media screen and (max-width: 600px) {
    .banner {
        display: none;
    }
} */
