$greyish-blue: #435475;
$light-grey: #f4f6fe;
$pale-blue: #a1b5e0;
$very-pale-blue: #ccd5e8;
$default-blue: #009ee4;
$default-dark-blue: #002e8c;
$dark-blue: #435475;
$default-purple: #691aaa;

@mixin sectionBaseStyles {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@mixin sectionHeading($font-color: null) {
    color: $font-color;
    font-size: 50px;
    font-weight: 400;

    @media screen and (max-width: 1600px) {
        font-size: 2em;
    }

    @media screen and (max-width: 640px) {
        font-size: 2.2em;
    }
}

@mixin quoteStyles($font-color: null) {
    font-weight: 500;
    font-style: italic;
    font-size: 20px;
    color: $font-color;
    margin: 1em 0;
}

@mixin quoteAuthorStyles($font-color: null) {
    font-weight: 500;
    font-size: 0.8em;
    color: $font-color;
}

@mixin sectionFooter($font-color: null) {
    color: $font-color;
    font-style: italic;
    font-size: 1.6em;
    margin-top: 1em;

    @media screen and (max-width: 1600px) {
        font-size: 1.3em;
    }
}

.landingContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: Poppins, sans-serif;
    background: $light-grey;
    padding: 0;
}

.navAndHeroSection {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .flex-btns {
        margin: 1em;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 900px) {
            flex-direction: column;
        }
    }
    .scroller {
        margin: auto;
    }
    .bannerr {
        // margin-top: 90px;
        text-align: center;
        line-height: 30px;
        display: flex;
        letter-spacing: 0px;
        color: #002e8c;
        opacity: 1;
        font-weight: 500;
        justify-content: center;
        position: fixed;
        width: 100%;
        height: 67px;
        background: #f4f6fe;
        z-index: 10;
        .txt {
            margin: auto 2px;
            font-size: 1.1em;
        }
        img {
            width: 30px;
            height: 30px;
            margin: auto 0;
        }
        #bnb {
            width: 27px;

            margin: auto 5px auto 8px;
        }
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
    .navAndHeroSection__hero {
        padding: 5vh 6vw 5vh;
        // height: 56.25vw;
        background: #00194b;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        text-align: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../public/Header Banner.png");

        margin-top: 109px;

        @media screen and (max-width: 600px) {
            margin-top: 70px;
            padding: 10px;
            background-size: cover;
        }
        @media screen and (max-width: 900px) {
            background-size: cover;
        }
        & > span:first-child {
            font-size: 2.5em;
            font-weight: 300;

            margin: 0 auto;

            // margin-bottom: 0.5em;

            color: #ffffff;
            opacity: 0.7;
            line-height: 8vh;
            @media screen and (max-width: 600px) {
                font-size: 2.5rem;
                margin-top: 12vh;
            }
            @media screen and (max-width: 1100px) {
                margin-top: 7vh;
            }
        }
        .para {
            width: 42%;
            margin: 1rem auto 2rem;
            // opacity: 0.5;
            font-size: 1.1em;
            line-height: 28px;
            font-family: "Poppins";
            line-height: 36px;
            color: #ffffff80;

            b {
                opacity: 1;
                font-weight: 600;
                color: #ffffffb3;
            }
            @media screen and (max-width: 900px) {
                width: 72%;
            }
        }
        .live {
            color: #009ee4;
            font-size: 3.4em;
        }
        & > .valuess {
            display: flex;
            width: 100%;
            justify-content: space-between;
            // margin: 2rem 0 1rem;
            @media screen and (max-width: 1400px) {
                justify-content: center;

                flex-wrap: wrap;
                .T {
                    margin: 2em 0;
                }
                .boxxx_ {
                    margin: 2em 0;
                }
            }
            .boxxx_ {
                width: -webkit-fill-available;

                margin: 2em 0;
            }
            .T {
                display: flex;
                flex-direction: column;
                margin: 1em;
            }
            .t1 {
                font-size: 2.5em;
            }
            .t2 {
                opacity: 0.7;
                font-size: 20px;
            }
            .t22 {
                opacity: 0.7;
                font-size: 20px;
            }
            @media screen and (max-width: 900px) {
                display: flex;
                width: 100%;
                justify-content: center;
                margin: 4em 0;
                flex-wrap: wrap;
                padding: 0;
                .T {
                    margin: 1em 2em;
                }
            }
        }
        .unit {
            color: #a1b5e0;
            // display: block;
            font-size: 15px;
            text-transform: lowercase;
            margin-left: -10px;
            // position: absolute;
            // bottom: 14px;
            // right: -40px;
        }
    }

    .navAndHeroSection__heroElephant {
        position: absolute;
        bottom: -12%;
        left: -2%;
        z-index: 1;

        img {
            width: 320px;
        }

        @media screen and (max-width: 1600px) {
            bottom: -9%;

            img {
                width: 260px;
            }
        }
    }

    .button__ {
        padding: 10px 20px !important;
        background: #000752 !important;
        color: rgb(31, 208, 255) !important;
        margin: 1em auto !important;
        box-shadow: none !important;
        font-size: 1.1em !important;
        @media screen and (max-width: 900px) {
            margin: 2vh auto !important;
        }
    }
    .blockchain_logo {
        margin-right: 2em;
    }
    .signip-txt {
        text-align: center;
        font-size: 1em;
        opacity: 0.7;
        color: white;
        margin: auto;
    }
    .vertical_hr {
        margin: auto 0;
        width: 290px;
        background: linear-gradient(to right, #009ee34d, #ffffff1c);
        height: 1px;
        transform: rotate(90deg);
        @media screen and (max-width: 1400px) {
            display: none;
        }
    }
    .boxx_1 {
        display: flex;
        margin: auto;

        justify-content: center;
        .T {
            display: flex;
            flex-direction: column;
            margin: 1em;
        }
        .t1 {
            font-size: 2.5em;
        }
        .t2 {
            opacity: 0.7;
            font-size: 20px;
        }
    }
    .live_a {
        margin-top: 1em;
        color: #1fd0ff;
        text-decoration: underline;
        font-size: 1.1em;
    }
    .cefa {
        margin: 0 auto;
        font-size: 1.1em;
        line-height: 28px;
        font-family: "Poppins";
        line-height: 36px;
        color: white;
    }
}

.helloCETO {
    @include sectionBaseStyles;
    position: relative;
    padding: 5em 0;
    background-image: url("../public/dots_bg.png");
    & > span:first-child {
        margin-bottom: 1em;
        @include sectionHeading($default-blue);
    }
    .box-0 {
        display: flex;
        justify-content: space-around;
    }
    .box-1 {
        background-color: white;
        display: flex;
        flex-direction: column;

        box-shadow: 0px 0px 40px #7090b026;
        border-radius: 20px;
        width: 20%;
        padding: 1em;
        // height: 300px;
    }
    .box_icon {
        width: 35%;
        height: 26%;

        margin: 60px auto 1em auto;
    }
    .box_txt {
        letter-spacing: 0px;
        color: #691aaa;
        opacity: 1;
        text-align: center;
        font-size: 1em;
        margin-bottom: 2rem;
    }
    @media screen and (max-width: 1200px) {
        .box_icon {
            width: 4rem;
            height: 100px;

            margin: 0px auto 1em auto;
        }

        .box_txt {
            letter-spacing: 0px;
            color: #691aaa;
            opacity: 1;
            text-align: center;
        }
    }
    @media screen and (max-width: 700px) {
        .box-0 {
            flex-wrap: wrap;
        }
        .box-1 {
            width: 40%;
            margin: 1rem;
        }
    }
    @media screen and (max-width: 500px) {
        .box-0 {
            flex-direction: column;
        }
        .box-1 {
            width: 87%;
            justify-self: center;
            margin: 1rem auto;
        }
    }
}

.saddleUp {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4em 15vw;
    background: linear-gradient(to right, #002e8c, #691aaa);

    @media screen and (max-width: 1600px) {
        padding: 4em 10vw;
    }
    @media screen and (max-width: 1100px) {
        flex-direction: column;
        padding: 4em 5vw;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 4em 2vw;
    }

    & > div:first-child {
        & > span:first-child {
            @include sectionHeading($default-blue);
            font-size: 4em;

            @media screen and (max-width: 1600px) {
                font-size: 3em;
            }
        }
    }

    .id {
        width: 65%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        color: $very-pale-blue;
        font-size: 18px;
        line-height: 2.4;
        font-weight: 500;
        @media screen and (max-width: 1100px) {
            margin: 40px 0 60px 0;
        }

        span {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0.2em 0;

            img {
                width: 18px;
                margin-right: 12px;
                margin-top: 1%;

                @media screen and (max-width: 1600px) {
                    width: 14px;
                }
            }
        }

        ul {
            list-style-type: none;
            padding-left: 5em;
            @media screen and (max-width: 420px) {
                padding-left: 2em;
            }

            li {
                position: relative;
                @media screen and (max-width: 768px) {
                    font-size: 14px;
                }

                b {
                    color: #fff;
                }

                &::before {
                    content: "" !important;
                    position: absolute;
                    background: $default-blue;
                    width: 15px;
                    top: 50%;
                    left: -7%;
                    height: 2.6px;
                    border-radius: 4px;
                    border: none;
                }
            }
        }

        @media screen and (max-width: 1600px) {
            width: 70%;
        }
        @media screen and (max-width: 1100px) {
            width: 90%;
        }
    }

    .webshow {
        @media screen and (max-width: 1100px) {
            display: none !important;
        }
    }

    .mobileshow {
        @media screen and (min-width: 1100px) {
            display: none !important;
        }
    }
}

.hourglass {
    @include sectionBaseStyles;
    padding: 4em 5vw;

    & > span:first-child {
        margin-bottom: 80px;
        @include sectionHeading($default-blue);
    }
    img {
        width: 20px;
        height: 20px;
        margin: auto;
    }
    .down_icon {
        margin: 2rem;
        text-align: right;
        justify-self: center;
        margin-left: 65%;
    }
    .box-0 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0px 6vw;

        .box-1 {
            width: 22%;
            margin-bottom: 100px;
            margin: 20px;

            @media screen and (max-width: 600px) {
                width: 37%;
                margin: 0px;

                margin-bottom: 100px;
            }
            @media screen and (max-width: 500px) {
                width: 97%;
                margin: 0px;

                margin-bottom: 100px;
            }
        }
        .SNo {
            text-align: left;

            letter-spacing: 0px;
            color: #691aaa;
            opacity: 1;
            font-size: 50px;
            font-weight: 500;
            @media screen and (max-width: 600px) {
                font-size: 2rem;
            }
        }
        .box_txt {
            line-height: 30px;
            text-align: left;
            letter-spacing: 0px;
            color: #002e8c;
            opacity: 1;
            font-weight: 500;

            @media screen and (min-width: 900px) {
                font-size: 18px;
            }
        }
    }
}

.strength {
    @include sectionBaseStyles;
    padding: 4em 20vw;
    background: #e9ecf8;
    @media screen and (max-width: 1100px) {
        padding: 4em 5vw;
    }

    & > span:first-child {
        @include sectionHeading($default-blue);
        font-weight: 300;
        @media screen and (max-width: 1100px) {
            font-size: 50px;
        }
    }

    & > span:nth-child(2) {
        @include quoteStyles($default-dark-blue);
        font-weight: bold;
    }

    & > span:nth-child(3) {
        color: $dark-blue;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        margin-top: 1.5em;
        margin-bottom: 2.4em;
        line-height: 2;
    }
}

.wisdom {
    @include sectionBaseStyles;
    padding: 4em 0;

    & > span:first-child {
        @include sectionHeading($default-blue);
    }

    .wisdom__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5vw;
        @media screen and (max-width: 1100px) {
            flex-direction: column;
        }

        img {
            width: 580px;

            @media screen and (max-width: 1600px) {
                width: 460px;
            }

            @media screen and (max-width: 640px) {
                width: 100%;

                margin-top: 2em;
            }
        }

        & > div:first-child {
            width: 35%;

            @media screen and (max-width: 1600px) {
                width: 32%;
            }
        }

        & > div:last-child {
            width: 60%;

            @media screen and (max-width: 1600px) {
                width: 65%;
            }
        }

        .wisdom__contentSliderPart {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            overflow-y: hidden;
            overflow-x: auto;
            padding: 1em 0;

            &::-webkit-scrollbar {
                background: transparent;
                height: 7px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 24px;
                background: $default-blue;
            }

            &::-webkit-scrollbar-track {
                border-radius: 24px;
                background: rgba(0, 160, 228, 0.1);
            }

            .wisdom__sliderContainer {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                font-weight: 500;
                font-size: 14px;
                overflow: hidden;
                margin: 4em 0;

                .wisdom__slide {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    max-width: 340px;
                    min-width: 330px;
                    text-align: left;
                    margin-left: 5em;

                    & > span:first-child {
                        position: relative;
                        font-size: 20px;
                        color: $default-purple;
                        text-transform: uppercase;
                    }

                    & > span:first-child::before {
                        content: "";
                        position: absolute;
                        top: 5%;
                        left: -15px;
                        height: 80%;
                        width: 3px;
                        background: $default-blue;
                        opacity: 0.6;
                    }

                    & > span:last-child {
                        color: $greyish-blue;
                        font-size: 18px;
                        margin-top: 0.8em;
                    }

                    @media screen and (max-width: 1600px) {
                        margin-left: 3.4em;
                        max-width: 300px;
                        min-width: 320px;

                        & > span:first-child {
                            font-size: 15px;
                        }

                        & > span:last-child {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 640px) {
            & {
                flex-wrap: wrap;
                justify-content: center;

                & > div {
                    width: 100% !important;
                }
            }
        }
    }
}

.loyalty {
    @include sectionBaseStyles;
    padding: 3em 5vw;

    & > span:first-child {
        @include sectionHeading($default-blue);
    }

    & > div {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 3em 0;
        @media screen and (max-width: 1100px) {
            flex-direction: column;
        }

        .loyalty-card {
            width: 28.64vw;
            height: 320px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #fff;
            border-radius: 20px;
            padding: 1em 2.4em;
            box-shadow: 0 0 40px #7090b026;
            color: $dark-blue;
            font-weight: 500;
            text-align: center;
            margin: 1em;
            @media screen and (max-width: 1100px) {
                width: 65vw;
            }

            span {
                color: $default-purple;
                font-size: 28px;
                margin-bottom: 0.8em;
            }
        }
    }
}

.feedUsPeanuts {
    @include sectionBaseStyles;
    padding: 3em 5vw;
    background: url("../public/feed-us-peanuts.png");
    background-repeat: no-repeat;
    background-size: cover;

    & > span:first-child {
        @include sectionHeading(#fff);
    }

    div {
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 3em;
        @media screen and (max-width: 748px) {
            width: 90%;
        }

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $very-pale-blue;
            font-size: 19px;
            font-weight: 300;
            letter-spacing: 2px;
            text-decoration: none;
            img {
                width: 64px;
                height: 53px;
                margin-bottom: 0.65em;
            }

            @media screen and (max-width: 1600px) {
                font-size: 16px;
            }
        }
    }
}

.leadTheHerd {
    @include sectionBaseStyles;
    position: relative;
    padding: 4em 10vw;
    margin-bottom: 2em;
    overflow: hidden;

    @media screen and (max-width: 640px) {
        align-items: flex-start;
        & {
            padding: 4em 6vw;
        }
    }

    & > span:first-child {
        @include sectionHeading($default-blue);
        text-align: center;
    }

    .leadTheHerd__content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 4em;
        justify-content: space-between;
        @media screen and (max-width: 1100px) {
            // flex-direction: column;
            padding-bottom: 200px;
            margin-left: 5vw;
            width: auto;
        }
        @media screen and (max-width: 600px) {
            justify-content: center;
            margin-left: 8vw;
        }
        .leadTheHerd__contentRowBlock {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 320px;
            text-align: left;
            margin-right: 4.1vw;
            margin-bottom: 4.68vw;
            @media screen and (max-width: 600px) {
                width: 100%;
            }
            & > span:first-child {
                position: relative;
                font-size: 20px;
                font-weight: 500;
                color: $default-purple;
                text-transform: uppercase;

                img {
                    position: absolute;
                    top: 5%;
                    left: -34px;
                    width: 24px;
                }
            }

            & > span:last-child {
                color: $greyish-blue;
                font-size: 16px;
                font-weight: 500;
                margin-top: 0.8em;
            }
            .lastt {
                color: $greyish-blue;
                font-size: 18px;
                font-weight: 500;
                margin-top: 0.8em;
            }
            @media screen and (max-width: 1600px) {
                // max-width: 240px;

                & > span:first-child {
                    img {
                        width: 18px;
                    }
                }

                @media screen and (max-width: 640px) {
                    & {
                        margin-bottom: 1.4em;
                    }
                }
            }

            @media screen and (max-width: 640px) {
                & {
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                }
            }
        }

        .leadTheHerd__contentFooter {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin: 0 auto;
            & > span:first-child {
                position: relative;
                font-size: 22px;
                font-weight: 500;
                color: $default-purple;
                text-transform: uppercase;

                img {
                    position: absolute;
                    top: 5%;
                    left: -34px;
                    width: 24px;
                }
            }

            & > span:nth-child(2) {
                color: $greyish-blue;
                font-size: 18px;
                font-weight: 500;
                margin-top: 1em;
                margin-bottom: 2em;
            }

            @media screen and (max-width: 1600px) {
                & > span:first-child {
                    img {
                        width: 18px;
                    }
                }
            }

            @media screen and (max-width: 640px) {
                width: 280px;
                align-items: flex-start;
                text-align: left;
                margin-left: auto;
            }
        }

        .leadTheHerd__elephant {
            position: absolute;
            bottom: 0;
            left: -6%;
            z-index: 1;

            @media screen and (max-width: 640px) {
                left: -10%;
            }
        }
    }
}
.rideWithPride {
    @include sectionBaseStyles;
    flex-direction: row;
    justify-content: space-between;
    padding: 3em 18vw;
    background: #e9ecf8;
    @media screen and (max-width: 1100px) {
        flex-direction: column;
        padding: 2em 5vw;
        .upper {
            margin-bottom: 1em;
        }
    }

    & > span:first-child {
        @include sectionHeading($default-blue);
    }

    & > span:last-child {
        width: 60%;
        color: $greyish-blue;
        font-size: 18px;
        font-weight: 500;
        text-align: left;

        @media screen and (max-width: 1600px) {
            font-size: 15px;
        }
        @media screen and (max-width: 600px) {
            width: 90%;
            color: #435475;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
        }
    }
}

.questionsContainer {
    @include sectionBaseStyles;
    padding: 5em 10vw;
    @media screen and (max-width: 600px) {
        padding: 5em 4vw;
    }
    & > span:first-child {
        @include sectionHeading($default-blue);
    }

    .questionsBlock {
        width: 100%;
        padding: 3em 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        text-align: left;

        @media screen and (max-width: 1600px) {
            font-size: 15px;
        }

        & > .question:not(:last-child) {
            margin-bottom: 1.4em;
        }

        .question {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $greyish-blue;
            font-weight: 600;
            padding: 8px 2em;
            border-radius: 16px;
            box-shadow: 0 0 40px #7090b026;
        }

        .answer {
            width: 100%;
            color: $greyish-blue;
            font-weight: 500;
            padding: 1em 2.4em;
            padding-bottom: 2em;
            background: transparent;

            div {
                margin-bottom: 1em;
            }
            a {
                text-decoration: underline;
            }
            ul {
                margin-left: 2em;
                list-style: none;

                li {
                    margin: 8px 0;
                }

                li::before {
                    content: "•";
                    color: $default-blue;
                    font-weight: bold;
                    font-size: 1.2em;
                    display: inline-block;
                    width: 1em;
                }
            }
            ul li::before {
                content: url(https://res.cloudinary.com/dgdjabqwr/image/upload/v1610015801/cd3d/CETO_-_Icon_-_Cyan_1_n7ezmc.png) !important;

                font-weight: bold;
                font-size: 1.5em !important;
                display: inline-block;
                width: 1em;
            }
        }
    }
}
.l {
    @media screen and (max-width: 600px) {
        display: none !important;
    }
}
.s {
    @media screen and (min-width: 600px) {
        display: none !important;
    }
}
